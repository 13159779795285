@charset "UTF-8";
/*
------------------------------------------------------
	Estilos de Bomberos a partir de 04/10/2016
------------------------------------------------------
*/
/* Nav
================================================= */
@media screen and (max-width: 800px) {
  .dropdown-menu .sub-menu {
    visibility: visible;
    position: relative;
    top: inherit;
    left: inherit;
    width: 100%;
    padding-left: 15px;
    background-color: transparent;
    display: block;
    border: 0;
    box-shadow: none;
  }

  .nav .caret-right {
    display: inline-block !important;
    position: relative !important;
    right: 0 !important;
    left: 5px !important;
    opacity: 1 !important;
    /*Cambiar orientación*/
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
    border-bottom: 0;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}
/* Icons famfamfam
================================================= */
.cus-page-attach {
  background-position: -609px -84px;
}

.cus-page-white-acrobat {
  background-position: -1029px -84px;
}

/* General
================================================= */
.textCenter {
  text-align: center !important;
}

.bgGreyLight {
  background-color: #dfe3e8 !important;
}

.bgGrey {
  background-color: #CCD1D9 !important;
}

.bgGreyDark {
  background-color: #AAB2BD !important;
}

.bgYellowLight {
  background-color: #ffe198 !important;
}

.bgYellow {
  background-color: #FFCE54 !important;
}

.bgYellowDark {
  background-color: #ffbb10 !important;
}

.bgBlueDark {
  background-color: #2494be !important;
  color: #fff !important;
  text-shadow: 1px 1px 1px #616161;
}

.bgMintLight {
  background-color: #7eddc5 !important;
}

.bgMint {
  background-color: #48CFAD !important;
}

.bgMintDark {
  background-color: #2fb593 !important;
}

/* Form
================================================= */
.controls input:focus {
  border-color: #e9322d;
  -webkit-box-shadow: 0 0 6px #d59392;
  -moz-box-shadow: 0 0 6px #d59392;
  box-shadow: 0 0 6px #d59392;
}

label.radio.block {
  width: 100%;
}

label.radio input,
label.radio input:focus {
  box-shadow: inset 0 0 0 !important;
}

/* Acordeon para agrupar campos en formulario
================================================= */
.groupColumn {
  padding-top: 5px;
}

.span6 .groupColumn {
  width: 280px;
}

.span12 .groupColumn {
  width: 540px;
}

.groupElement {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.groupElement .checkbox_2 {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 5px;
}

.groupElement .checkbox_2:last-child {
  margin-bottom: 8px;
}

.checkbox_2 label {
  margin-bottom: 0;
}

a.linkAccordion {
  color: #951921;
  position: relative;
  display: block;
  padding: 0.2em 0.5em;
}

a.linkAccordion .caret {
  vertical-align: middle;
  margin-left: 0.5em;
  float: right;
  margin-top: 8px;
}

a.linkAccordion:hover,
a.linkAccordion:hover .caret {
  color: #f2911e;
  border-top-color: #f2911e;
  opacity: 1;
}

/*-- Dos columnas --*/
.span12 .groupColumn.columnTwo {
  width: 100%;
}

.columnTwo .groupElement .checkbox_2 {
  width: 48%;
  float: left;
  display: inline-block;
}

/* xs ----------------------------------------------------- */
@media screen and (max-width: 768px) {
  .groupColumn,
.span12 .groupColumn,
.span6 .groupColumn {
    width: 100%;
  }
}
/* Tabla Scroll
================================================= */
.tableScroll {
  overflow-y: auto;
}

/* Form Login
===================================================*/
.form-login {
  margin-bottom: 0;
}

.form-login .control-group .controls input[type=checkbox] {
  margin-top: 8px;
}

.form-login .controls p {
  display: inline-block;
  float: right;
  margin-right: 25px;
  margin-top: 6px;
  margin-bottom: 0;
  margin-left: 20px;
  color: #999999;
  vertical-align: middle;
}

.form-login .form-actions {
  padding-bottom: 0;
  margin-bottom: 0;
}

.form-login .form-actions button {
  margin-bottom: 10px;
}

.form-login .form-actions p {
  display: inline-block;
  margin-left: 10px;
  color: #999999;
}

.form-login .footer {
  margin-top: 15px;
  padding: 10px;
  text-align: center;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.form-login .footer p {
  margin: 0;
  color: #7b7b7b;
}

.form-login .footer p small {
  color: #757575 !important;
}

.form-login .footer a {
  margin-left: 5px;
}

.highlightBox {
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  color: #999999;
}

.highlightBox p {
  margin: 0;
  display: inline-block;
  margin-right: 10px;
}

/* Dashboard Bomberos
==============================================*/
.profileContainer, .widgetContainer {
  display: inline-block;
  float: left;
}

.profileContainer {
  width: 30%;
  float: right;
  margin-top: 65px;
}

.profileBox {
  position: relative;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: 1px solid #e9e9e9;
}

.profileBox .avatar {
  position: absolute;
  top: -55px;
  left: 50%;
  margin-left: -56px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #1FB3EA;
  border: 4px solid rgba(255, 255, 255, 0.5);
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
}

.profileBox .avatar img {
  width: 100%;
  height: auto;
  display: block;
}

.profileBox .info {
  padding: 10px;
  padding-top: 70px;
}

.profileBox .info h3 {
  text-align: center;
  margin-bottom: 10px;
}

.profileBox .info p {
  padding-left: 5px;
  color: #999999;
  margin-bottom: 5px;
}

.profileBox .info p.label {
  background-color: transparent;
  text-shadow: none;
  width: 50px;
  text-align: right;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  font-weight: normal;
  padding-top: 3px;
}

.profileBox .info p.text {
  display: inline-block;
  width: 180px;
  text-shadow: none;
  color: #333333;
}

.profileBox .footer {
  text-align: center;
  padding: 15px;
  border-top: 1px solid #e5e5e5;
}

.profileBox .footer a {
  margin-left: 4px;
  margin-right: 4px;
}

.widgetContainer {
  width: 70%;
  /*display: table;*/
  margin-bottom: 20px;
  margin-top: 20px;
}

.widgetRow {
  /*display: table-row;*/
  width: 100%;
  clear: both;
}

.widgetRow > .widgetBox {
  width: 26%;
  display: inline-block;
  margin-right: 2%;
}

.widgetBox {
  /*display: table-cell;*/
  padding: 15px;
  text-align: center;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  color: #999999;
  background-color: #f5f5f5;
}

.widgetBox .counter {
  width: 65px;
  height: 65px;
  line-height: 65px;
  margin-left: auto;
  margin-right: auto;
  background-color: #939393;
  text-align: center;
  font-size: 40px;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 15px;
}

.widgetBox h3 {
  padding: 5px 20px;
  vertical-align: top;
  font-size: 16px;
  text-transform: uppercase;
  background-color: #B42025;
  color: #ffffff;
  display: inline-block;
}

.widgetMain {
  text-align: center;
  padding: 20px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  /*background-color: #59adff;*/
  /*background-color: #f2f2f2;*/
  border-radius: 5px;
  /*border: 1px solid #e9e9e9;*/
}

.widgetMain h3 {
  display: inline-block;
  vertical-align: top;
  background-color: #1f5992;
  color: #ffffff;
  padding: 5px 15px 5px 50px;
  margin-left: -50px;
  z-index: 290;
}

.widgetMain .counter {
  background-color: #59adff !important;
  z-index: 300;
  position: relative;
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin-left: auto;
  margin-right: auto;
  background-color: #B42025;
  text-align: center;
  font-size: 60px;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
}

/* Dashboar Bomberos 2 - Cuerpo - Federación
==============================================*/
.columnLarge {
  float: left;
  width: 100%;
  clear: both;
  margin-bottom: 2em;
}

.columnMedium {
  float: left;
  width: 70%;
  display: inline-block;
}

.columnSmall {
  float: left;
  width: 30%;
  display: inline-block;
}

.col3 {
  float: left;
  width: 31%;
  display: inline-block;
  margin-right: 1%;
  margin-left: 1%;
}

.col2 {
  float: left;
  width: 48%;
  display: inline-block;
  margin-right: 1%;
  margin-left: 1%;
}

.paddingMedium {
  padding: 0.5em;
}

.marginTopMedium {
  margin-top: 45px;
}

.pullRight {
  float: right !important;
}

.profile {
  text-align: center;
  position: relative;
  margin-top: 0px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.profile .avatar {
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  overflow: hidden;
}

.profile .avatar img {
  display: block;
  height: 100%;
  width: auto;
  position: inherit;
  top: inherit;
  left: inherit;
  margin-left: auto;
  margin-right: auto;
}

.profile .info {
  background-color: #9E191C;
  padding-top: 0;
  border: 1px solid #e4e4e4;
  padding-top: 84px;
  margin-top: -84px;
  border-radius: 4px;
}

.profile .info h3 {
  color: #ffffff;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
  padding-bottom: 0.2em;
  letter-spacing: 1px;
}

.profile .info .footer {
  padding: 1.2em;
  background-color: #f5f5f5;
}

.profile .info .footer a {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.itemContainer {
  display: table;
  width: 100%;
  background-color: #f5f5f5;
}

.itemContainer .item {
  display: table-row;
  /*background-color: rgba(255,255,255,.8);*/
}

.itemContainer .item .col {
  display: table-cell;
  border-top: 1px solid #e3e3e3;
  padding: 0.6em;
}

.itemContainer .item:last-child .col {
  border-bottom: 1px solid #e3e3e3;
}

.itemContainer .item .col p {
  margin-bottom: 0;
  font-size: 14px;
  text-align: left;
}

.itemContainer .item .col p.title {
  text-align: right;
  background-color: transparent;
  border-radius: 0;
  color: #909090;
  font-size: 12px;
}

.widgetFull {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;
}

.widgetFull .number {
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
  color: #ffffff;
  padding: 0.2em 0.4em;
  background-color: #f39c12;
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;
  /*border-top: 6px solid #1F5992;*/
  border-top: 6px solid #B42025;
  vertical-align: top;
  border-left: 4px solid rgba(255, 255, 255, 0.5);
  border-bottom: 4px solid rgba(255, 255, 255, 0.5);
}

.widgetFull .title {
  position: relative;
  display: inline-block;
  color: #ffffff;
  /*background-color: #1F5992;*/
  background-color: #B42025;
  padding: 0.4em 1.2em;
  vertical-align: top;
  margin-left: -4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.widgetFull .title h3 {
  color: #ffffff;
  font-weight: normal;
}

.widgetFull .title a {
  background-color: #f5f5f5;
  position: absolute;
  bottom: -23px;
  right: 0;
  padding: 0.2em 0.8em;
}

.widgetBlock {
  position: relative;
  text-align: center;
  background-color: #f5f5f5;
  padding: 1em;
  padding-top: 0;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.widgetBlock .number {
  display: inline-block;
  font-size: 35px;
  line-height: 40px;
  color: #ffffff;
  padding: 0.2em 0.6em;
  background-color: #939393;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  border-left: 4px solid rgba(255, 255, 255, 0.5);
  border-right: 4px solid rgba(255, 255, 255, 0.5);
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
  vertical-align: top;
  margin-bottom: 10px;
}

.widgetBlock .title p {
  color: #909090;
}

.widgetBlock .title h3 {
  background-color: #1F5992;
  /*background-color: #B42025;*/
  padding: 0.2em 0.4em;
  color: #ffffff;
  letter-spacing: 2px;
  font-size: 14px;
  line-height: 18px;
}

.widgetBlock p {
  margin-top: 0.5em;
  margin-bottom: 0;
  line-height: 16px;
}

.widgetBlock a {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em 1em;
}

.widgetBlock.minHeight {
  min-height: 216px;
}

.widgetBlock.minHeight2 {
  min-height: 178px;
}

.widgetLink {
  margin-left: 0;
}

.widgetLink li {
  display: inline-block;
  list-style: none;
  width: 30%;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 1em;
  vertical-align: top;
}

.widgetLink li a {
  display: block;
  padding: 0.5em 1em;
  background-color: #49afcd;
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}

.widgetLink li a {
  display: block;
  padding: 0.5em 1em;
  background-color: #49afcd;
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}

.widgetLink li a span {
  display: block;
  font-size: 13px;
  margin-bottom: 0.3em;
  text-shadow: none;
  color: rgba(255, 255, 255, 0.6);
}

.widgetLink li a h4 {
  color: #ffffff;
}

/* xs ----------------------------------------------------- */
@media screen and (max-width: 800px) {
  .columnSmall,
.columnMedium,
.col3,
.col2 {
    float: left;
    width: 100%;
  }

  .widgetLink li {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
/* Login - Register
==============================================*/
.bgLine {
  margin-top: -15px;
  min-height: 500px;
  /*background-color: #f2f2f2; /* fallback for old browsers */
  background-color: -webkit-linear-gradient(to top, #1ce, #FFB74B);
  /* Chrome 10-25, Safari 5.1-6 */
  background-color: linear-gradient(to top, #1ce, #FFB74B);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /*background-image: url('../img/bg-login.png');*/
  background-image: url("../img/bg-line.png");
}

.well.bgWhite {
  background-color: #ffffff;
  padding: 1.5em;
  box-shadow: none;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}

.well.bgWhite input {
  background-color: #f2f2f2;
}

.boxRegister {
  /*margin-top: 20px;*/
  position: relative;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #9E191C;
  border-radius: 4px;
  background: -webkit-linear-gradient(to top, #9E191C, #F33137);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #9E191C, #F33137);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.boxRegister.info {
  background: -webkit-linear-gradient(to top, #0b5976, #8cd8f4);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #0b5976, #8cd8f4);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.boxRegister .avatar {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: #1FB3EA;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  display: inline-block;
  margin-left: 30px;
  border: 4px solid rgba(255, 255, 255, 0.5);
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
}

.boxRegister .avatar ul {
  margin-left: 0;
  padding-left: 0;
}

.boxRegister .avatar ul li {
  list-style: none;
}

.boxRegister .avatar img {
  width: 100%;
  height: auto;
  display: none;
}

.boxRegister .dialogue {
  max-width: 220px;
  background-color: #ffffff;
  padding: 1em;
  width: auto;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.boxRegister .dialogue h3 {
  margin-bottom: 0.1em;
  margin-top: 0;
  color: #333333;
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
}

.buttonContainer {
  margin-top: 1em;
}

.boxRegister .dialogue:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  /* tringle bottom*/
  /*border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: 20px solid #ffffff;
  top: -35px;
  left: 50%;
  margin-left: -20px;*/
  /*Triangle right*/
  border-left: 20px solid #ffffff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  top: 50%;
  right: -10px;
  margin-top: -20px;
}

.dialogue a {
  margin-top: 1em;
}

.avatar {
  height: 150px;
  width: 150px;
  position: relative;
}

.avatar img {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.typeText {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
}

/*
===========================
Table List Bomberos
==========================
*/
table {
  border-collapse: inherit;
}

.span6-col {
  width: 50%;
  margin-left: 0 !important;
}

.span12-col {
  width: 100%;
  margin-left: 0 !important;
}

/*-- Labels --*/
.label {
  text-shadow: none;
  padding: 2px 6px 2px;
}

.center {
  text-align: center;
}

/*-- Iconos --*/
.icon-print-plus {
  display: inline-block;
  width: 17px;
  height: 16px;
  margin-right: 0.3em;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url("../img/famfamfam-icons.png");
  background-position: 14px 14px;
  background-repeat: no-repeat;
}

.icon-print-plus {
  background-position: -944px -105px;
}

/*-- Credencial --*/
.boxCredencial {
  background-size: 465px;
  height: 320px;
  background-repeat: no-repeat;
  text-transform: uppercase;
  width: 466px;
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
}

.boxCredencial p {
  margin-bottom: 0;
  font-size: 11px;
  line-height: 12px;
}

/*-- Frente --*/
.boxCredencial.frente {
  position: relative;
  background-image: url("../img/credencial-frente.png");
}

.boxCredencial.frente .title {
  padding-left: 170px;
  padding-right: 40px;
  padding-top: 32px;
  padding-bottom: 35px;
}

.boxCredencial.frente .title p strong {
  font-size: 10px;
}

.boxCredencial.frente .title p {
  font-size: 11px;
  font-weight: bold;
}

.boxCredencial.frente .info {
  padding-left: 40px;
}

.boxCredencial.frente .info .line {
  margin-bottom: 10px;
}

.boxCredencial.frente .info .line p {
  text-transform: capitalize;
  font-size: 10.5px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.boxCredencial.frente .info .line p strong {
  text-transform: uppercase;
  font-size: 11px;
}

.boxCredencial.frente .info .line.middle {
  margin-bottom: 0;
  width: 100px;
  display: inline-block;
}

.boxCredencial.frente .info p strong {
  font-size: 10.3px;
}

.boxCredencial.frente .info p {
  font-size: 11px;
  line-height: 11px;
  width: 255px;
}

.boxCredencial.frente .foto {
  position: absolute;
  top: 90px;
  right: 49px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 96px;
  height: 115px;
  background-size: auto 115px;
}

/*-- Dorso --*/
.boxCredencial.dorso {
  background-image: url("../img/credencial-dorso.png");
}

.boxCredencial.dorso .leyenda {
  text-align: center;
  padding: 30px;
  padding-top: 50px;
  padding-bottom: 0;
}

.boxCredencial.dorso .leyenda p {
  margin-bottom: 15px;
  font-size: 11.5px;
  line-height: 15px;
}

.cleanBoth {
  clear: both;
}

/*  Font bar code  */
@font-face {
  font-family: "Code39";
  src: url("../fonts/Code39Regular.eot");
  src: url("../fonts/Code39Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Code39Regular.woff2") format("woff2"), url("../fonts/Code39Regular.woff") format("woff"), url("../fonts/Code39Regular.ttf") format("truetype"), url("../fonts/Code39Regular.svg#Code39Regular") format("svg");
  font-weight: 100;
  font-style: normal;
}
.boxCredencial.dorso .codigoBarrasContainer {
  text-align: center;
  margin-top: 30px;
}

.boxCredencial.dorso .codigoBarras {
  text-align: center;
  background-color: #ffffff;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.boxCredencial.dorso .codigoBarras p {
  display: block;
  padding: 10px;
  width: auto;
  font-family: "code39";
  font-size: 60px;
  line-height: 60px;
  text-align: center;
}

/*
================================
File input image
================================
*/
/* File Upload */
.imgPreviewContainer {
  display: inline-block;
  width: 170px;
  height: 202px;
  overflow: hidden;
}

.imgPreviewContainer .thumbnail {
  width: 160px;
  height: auto;
}

p.textImgUpload {
  display: inline-block;
  max-width: 140px;
  vertical-align: top;
  margin-top: 1.5em;
  padding-left: 0.5em;
}

.inputGroupUpload {
  display: flex;
  margin-bottom: 0.5em;
}

.inputGroupUpload {
  margin-top: 0.5em;
}

input.nameFile {
  width: 100%;
  border: 0;
  border-radius: 0;
  box-shadow: none !important;
  padding-left: 0.5em;
  background-color: #ddd;
}

.inputGroupBtn {
  min-width: 86px;
}

/* File Upload */
.fileUpload {
  position: relative;
  overflow: hidden;
}

.fileUpload input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 33px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

/* Vista */
.imgUploadView {
  width: 170px;
  height: 202px;
  overflow: hidden;
}

.imgUploadView img {
  width: 160px;
  height: auto;
}

/*
==============================================
slideDown
==============================================
*/
.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}

@keyframes slideDown {
  0% {
    transform: translateY(-200%);
  }
  50% {
    transform: translateY(8%);
  }
  65% {
    transform: translateY(-4%);
  }
  80% {
    transform: translateY(4%);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(0%);
  }
}
@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-200%);
  }
  50% {
    -webkit-transform: translateY(8%);
  }
  65% {
    -webkit-transform: translateY(-4%);
  }
  80% {
    -webkit-transform: translateY(4%);
  }
  95% {
    -webkit-transform: translateY(-2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
/* Style Print */
.print .brands {
  float: inherit;
  text-align: right;
  margin-top: 0.4em;
}

.print h1 {
  color: #000000;
  font-size: 22px;
  border-bottom: 1px solid #000000;
  padding-bottom: 0.2em;
  line-height: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
  font-weight: bold;
}

.print ul.filter {
  width: 44%;
  float: left;
  display: inline-block;
}

.print ul.filter li {
  list-style: none;
}

.print ul.filter li strong {
  font-weight: bold !important;
}

.print .signatureContainer {
  margin-top: 80px;
  width: 100%;
  height: 100px;
}

.print .signatureContainer .signature {
  width: 200px;
  height: 100px;
  margin-left: auto;
}

.print .signatureContainer .signature .space {
  height: 76px;
}

.print .signatureContainer .signature p {
  text-align: center;
  border-top: 1px dashed #000000;
  padding-top: 0.4em;
  font-size: 10px;
}

body.print *,
.print * {
  page-break-inside: auto !important;
}

@media print {
  @page {
    size: A4 portrait;
    margin-top: 2cm;
    margin-bottom: 1.3cm;
    margin-left: 1.2cm;
    margin-right: 1.2cm;
  }
  @page :first {
    margin-top: 1.2cm;
  }
  body.print ul.filter li strong {
    font-weight: bold !important;
  }
}
/* PAGINADOR */
.paginationContainer {
  margin-bottom: 1.4em;
}

.paginationContainer ul {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}

.paginationContainer ul.pagination-right {
  float: right;
}

.paginationContainer ul.selector {
  margin-left: 0;
}

.paginationContainer ul.selector li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  color: #747474;
}

.btn.btn-transparent {
  background-image: none !important;
  border: 0 !important;
  box-shadow: none !important;
}

.paginationContainer ul.selector li:last-child a {
  margin-right: 0 !important;
}

.paginationContainer ul.selector li.disabled a,
.paginationContainer ul.selector li.disabled a:hover {
  background-color: transparent;
  cursor: not-allowed;
  opacity: 0.2;
}

input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.paginationContainer ul.selector li.error input {
  border-color: #b94a48;
}

.paginationContainer ul.selector li > a {
  margin-left: 1em !important;
  margin-right: 1em !important;
}

.paginationContainer ul.selector li input {
  width: 50px;
  margin-bottom: 0;
  margin-left: 0.3em;
  margin-right: 0.3em;
  text-align: center;
}

.paginationContainer ul.selector li .btn {
  margin-left: 0.3em;
}

.paginationContainer ul.pagination {
  margin-top: 0;
  height: 28px;
}

.paginationContainer .pagination a, .pagination span {
  line-height: 28px;
}

/* Estilos para el paginador de manera global*/
.pagination .active a,
.pagination .active span {
  background-color: #f2911e;
  color: #ffffff;
  border-color: #f2911e;
}

.pagination span {
  color: #939393;
}

.pagination a {
  color: #f2911e;
  background-color: #fff;
  border-color: #ccc;
}

.pagination a:hover {
  color: #951921;
}

.btn-grey {
  background-image: -ms-linear-gradient(top, #cecece, #9e9e9e);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#cecece), to(#9e9e9e));
  background-image: -webkit-linear-gradient(top, #cecece, #9e9e9e);
  background-image: -o-linear-gradient(top, #cecece, #9e9e9e);
  background-image: linear-gradient(top, #cecece, #9e9e9e);
  background-image: -moz-linear-gradient(top, #cecece, #9e9e9e);
  color: #ffffff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.3);
}
.btn-grey:hover {
  background-image: none;
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
  border-color: rgba(0, 0, 0, 0.1);
}

.btn:active, .btn:focus {
  outline: none !important;
}

button:focus {
  outline: none;
}

.panel-area {
  float: right;
  padding-right: 0;
  padding-top: 40px;
}
.panel-area .session-user,
.panel-area .notifications {
  display: inline-block;
}
.panel-area .session-user > a, .panel-area .session-user > button {
  position: relative;
  display: block;
  background-color: transparent;
  border: 0;
  padding: 0;
}
.panel-area .session-user > a .caret, .panel-area .session-user > button .caret {
  position: absolute;
  right: -9px;
  bottom: 6px;
  border-top: 6px solid #f3f3f3;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  opacity: 1;
}
.panel-area .session-user > a:hover .user-img, .panel-area .session-user > button:hover .user-img {
  border-color: #f39c12;
}
.panel-area .session-user .user-img {
  text-align: center;
  background-color: #ffffff;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #e5e5e5;
  margin-left: 0.6em;
}
.panel-area .session-user .user-img img {
  height: 100%;
  width: auto;
}
.panel-area .session-user.open button .user-img {
  border-color: #f39c12;
}
.panel-area .session-user.open > .dropdown-menu {
  left: inherit;
  right: 0;
  margin-top: 6px;
  padding-top: 0;
}
.panel-area .session-user.open > .dropdown-menu::before, .panel-area .session-user.open > .dropdown-menu::after {
  left: inherit;
  right: 10px;
}
.panel-area .session-user.open > .dropdown-menu li a i {
  margin-right: 0.4em;
}
.panel-area .session-user.open > .dropdown-menu li h3 {
  font-size: 14px;
  line-height: 17px;
  background-color: #f2f2f2;
  margin: 0;
  padding: 0.2em 0.6em;
  padding-top: 0.6em;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.panel-area .session-user.open > .dropdown-menu li h4 {
  margin: 0;
  text-transform: uppercase;
  background-color: #f2f2f2;
  letter-spacing: 1px;
  font-size: 10px;
  line-height: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 0.6em;
  color: #929292;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  margin-bottom: 0.6em;
}
.panel-area .notifications {
  vertical-align: bottom;
}
.panel-area .notifications > ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.panel-area .notifications > ul > li {
  position: relative;
  display: inline-block;
}
.panel-area .notifications > ul > li button {
  display: block;
  background-color: transparent;
  border: 0;
  padding-top: 6px;
}
.panel-area .notifications > ul > li button img {
  opacity: 0.86;
}
.panel-area .notifications > ul > li button:hover img {
  opacity: 1;
}
.panel-area .notifications > ul > li span.badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #adadad;
  padding: 1px 6px;
  border: 2px solid #B42025;
}
.panel-area .notifications > ul > li.open.maxHeight > .dropdown-menu li.body {
  max-height: 250px;
  overflow: auto;
}
.panel-area .notifications > ul > li.open button img {
  opacity: 1 !important;
}
.panel-area .notifications > ul > li.open > .dropdown-menu {
  white-space: normal;
  min-width: 240px;
  left: inherit;
  right: 0;
  margin-top: 6px;
  padding-bottom: 0;
  padding-top: 0;
}
.panel-area .notifications > ul > li.open > .dropdown-menu::before, .panel-area .notifications > ul > li.open > .dropdown-menu::after {
  left: inherit;
  right: 10px;
}
.panel-area .notifications > ul > li.open > .dropdown-menu li.header {
  background-color: #f3f3f3;
}
.panel-area .notifications > ul > li.open > .dropdown-menu li.header h4 {
  margin: 0;
  padding-bottom: 0.6em;
  padding-top: 0.8em;
  text-align: center;
  font-size: 11px;
  color: #999999;
  border-bottom: 1px solid #e5e5e5;
}
.panel-area .notifications > ul > li.open > .dropdown-menu li.body ul {
  padding-left: 0;
  margin-left: 0;
  list-style: none;
}
.panel-area .notifications > ul > li.open > .dropdown-menu li.body ul li {
  padding: 0;
}
.panel-area .notifications > ul > li.open > .dropdown-menu li.body ul li h5 {
  background-color: #ffffff;
  font-size: 12px;
  color: #333333;
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
}
.panel-area .notifications > ul > li.open > .dropdown-menu li.body ul li a {
  font-size: 14px;
  font-weight: bold;
  padding: 0.85em 1em;
  border-bottom: 1px solid #e5e5e5;
}
.panel-area .notifications > ul > li.open > .dropdown-menu li.body ul li a p {
  padding-top: 0.2em;
  margin-bottom: 0;
  color: #aaa;
  font-weight: normal;
  font-size: 12px;
}
.panel-area .notifications > ul > li.open > .dropdown-menu li.body ul li a:hover {
  background-color: #f5f5f5;
  color: initial;
}
.panel-area .notifications > ul > li.open > .dropdown-menu li.footer {
  background-color: #f3f3f3;
  text-align: center;
}
.panel-area .notifications > ul > li.open > .dropdown-menu li.footer a {
  color: #f39c12;
  font-weight: bold;
  font-size: 11px;
  padding: 1em;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.panel-area .notifications > ul > li.open > .dropdown-menu li.footer a:hover {
  background-color: #f3f3f3;
  color: #B42025;
}

.bgOrange {
  background-color: #f39c12 !important;
}

.i-bell {
  display: block;
  width: 40px;
  height: 45px;
}

.animation-bell {
  -webkit-animation: ring 3s 0.7s ease-in-out 1;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 3s 0.7s ease-in-out 1;
  -moz-transform-origin: 50% 4px;
  animation: ring 3s 0.7s ease-in-out 1;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  5% {
    -webkit-transform: rotateZ(32deg);
  }
  10% {
    -webkit-transform: rotateZ(-28deg);
  }
  15% {
    -webkit-transform: rotateZ(24deg);
  }
  20% {
    -webkit-transform: rotateZ(-20deg);
  }
  25% {
    -webkit-transform: rotateZ(16deg);
  }
  30% {
    -webkit-transform: rotateZ(-12deg);
  }
  34% {
    -webkit-transform: rotateZ(8deg);
  }
  38% {
    -webkit-transform: rotateZ(-5deg);
  }
  42% {
    -webkit-transform: rotateZ(2deg);
  }
  46% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}
@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  5% {
    -moz-transform: rotate(32deg);
  }
  10% {
    -moz-transform: rotate(-28deg);
  }
  15% {
    -moz-transform: rotate(24deg);
  }
  20% {
    -moz-transform: rotate(-20deg);
  }
  25% {
    -moz-transform: rotate(16deg);
  }
  30% {
    -moz-transform: rotate(-12deg);
  }
  34% {
    -moz-transform: rotate(8deg);
  }
  38% {
    -moz-transform: rotate(-5deg);
  }
  42% {
    -moz-transform: rotate(2deg);
  }
  46% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}
@keyframes ring {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(32deg);
  }
  10% {
    transform: rotate(-28deg);
  }
  15% {
    transform: rotate(24deg);
  }
  20% {
    transform: rotate(-20deg);
  }
  25% {
    transform: rotate(16deg);
  }
  30% {
    transform: rotate(-12deg);
  }
  34% {
    transform: rotate(8deg);
  }
  38% {
    transform: rotate(-5deg);
  }
  42% {
    transform: rotate(2deg);
  }
  46% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
.navbar .brand {
  padding: 0.4em;
  margin-right: 0.8em;
}

fieldset > .span6-col {
  margin-left: 0 !important;
}

.well fieldset h3 {
  padding-top: 16px;
}
.well fieldset h3.first {
  padding-top: 10px;
}

.input-mini {
  width: 40px;
}

.span12-col > .btn-container {
  top: 14px;
}
.span12-col .form-actions {
  margin-bottom: 0;
  padding-bottom: 0;
}

.select2-container.input-xlarge {
  width: 100%;
}

table tr.highlightGrey td {
  color: #b5b5b5;
}
table tr.highlightGrey td i.icon-eye-open {
  opacity: 0.4;
}

.btn i {
  margin-right: 0.3em;
}

.btn-group {
  margin-bottom: 0.4em;
}

.controls > input,
input,
.controls > textarea,
textarea,
.controls > select,
select,
.form-inline > input,
.form-inline > select,
.form-inline > textarea {
  box-shadow: none !important;
}
.controls > input:focus,
input:focus,
.controls > textarea:focus,
textarea:focus,
.controls > select:focus,
select:focus,
.form-inline > input:focus,
.form-inline > select:focus,
.form-inline > textarea:focus {
  border-color: #52b8e8 !important;
  box-shadow: 0 0 8px rgba(71, 168, 216, 0.6) !important;
}

input.select2-input,
input {
  box-shadow: none !important;
}

.select2-container-multi .select2-choices,
.select2-container .select2-choice {
  box-shadow: none !important;
}

.select2-container.select2-container-active .select2-choices,
.select2-container.select2-container-active .select2-choice {
  border-color: #52b8e8 !important;
  box-shadow: 0 0 8px rgba(71, 168, 216, 0.6) !important;
}

.select2-drop.select2-drop-above.select2-drop-active {
  border-color: #52b8e8 !important;
  border-top-color: #52b8e8 !important;
  border-bottom-color: #52b8e8 !important;
}

.controls > .select2-container {
  width: 100%;
  max-width: 280px;
}
.controls > .controls-checkbox > label {
  vertical-align: sub;
  cursor: pointer;
  display: inline-block;
  margin-right: 1em;
}

.span12-col .control-group .controls .table-list input {
  width: 100%;
}
.span12-col .control-group .controls .table-list input:focus {
  outline: none !important;
  box-shadow: none !important;
}
.span12-col .control-group .controls .table-list input[type=file] {
  cursor: pointer;
  padding-left: 0.4em;
}
.span12-col .control-group .controls .table-list input[type=file]:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

input[type=file] {
  cursor: pointer;
  padding-left: 0.4em;
}
input[type=file]:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.form-inline {
  margin-bottom: 0;
  margin-top: 10px;
}
.form-inline input,
.form-inline select,
.form-inline .select2-container,
.form-inline textarea {
  margin-right: 6px;
}
.form-inline > label {
  padding-right: 4px;
  vertical-align: top;
  padding-top: 6px;
}
.form-inline > button {
  margin-bottom: 8px;
  margin-left: 4px;
}

div.form-horizontal > .row {
  margin-left: 0;
  margin-top: 0;
}
div.form-horizontal > .row > .span6-col .control-group {
  padding-left: 0.4em;
  padding-right: 0.4em;
}

form.form-list {
  margin-bottom: 0;
}

.form-horizontal.large .control-group > label {
  width: 380px !important;
}
.form-horizontal.large .control-group > .controls {
  margin-left: 400px;
}
.form-horizontal.large .control-group > .controls input[type=file] {
  width: 100%;
}
.form-horizontal.large .control-group.showField {
  display: block !important;
}
.form-horizontal.large .form-actions {
  text-align: center;
  padding: 20px;
}

.form-actions a, .form-actions button {
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.tableForm td.center {
  width: 60px;
}
.tableForm select {
  width: auto;
  max-width: inherit;
  min-width: 100%;
}
.tableForm .select2-container {
  width: 100%;
}
.tableForm select.lg,
.tableForm .select2-container.lg {
  max-width: 240px !important;
  width: 100% !important;
  min-width: 1px;
}
.tableForm select.sm,
.tableForm .select2-container.sm {
  max-width: 130px !important;
  width: 100% !important;
  min-width: 1px;
}
.tableForm select.xs,
.tableForm .select2-container.xs {
  width: 160px !important;
  min-width: 1px;
}
.tableForm textarea {
  margin-bottom: 0;
}

.table {
  margin-top: 0.4em;
}

.form-full textarea {
  width: -webkit-fill-available;
  width: -moz-available;
  margin-left: 0;
  min-height: 100px;
}
.form-full .form-actions {
  text-align: center;
}

.control-group .controls > a {
  margin-bottom: 0;
}
.control-group .controls > a.btn-mini {
  margin-top: -8px;
  margin-bottom: 0.6em;
}
.control-group .controls table.table-list {
  margin-top: 0;
  margin-bottom: 1em;
}
.control-group .controls table.table-list tbody tr td {
  border-top: 0;
  border-bottom: 1px solid #dddddd;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  vertical-align: middle;
}
.control-group .controls table.table-list tbody tr td:first-child {
  padding-left: 0;
}
.control-group .controls table.table-list tbody tr td input {
  margin: 0;
  min-width: 1px;
  max-width: -webkit-fill-available;
  max-width: -moz-available;
}
.control-group .controls table.table-list tbody tr td input.input-small {
  width: 50px;
}
.control-group .controls table.table-list tbody tr td.xs {
  width: 30px;
  text-align: center;
}

.control-group.error .controls .select2-container .select2-choice {
  border-color: #b94a48;
}
.control-group.error .help-inline {
  display: block;
}

table.table-form tbody tr td > textarea {
  width: 100%;
  min-width: 200px;
  box-sizing: border-box;
}
table.table-form tbody tr td > select {
  width: 100%;
}
table.table-form tbody tr td input.input-xs {
  width: 50px !important;
}
table.table-form tbody tr td input.input-date {
  width: 90px !important;
}
table.table-form tbody tr td input.input-sm {
  width: 80px !important;
}
table.table-form tbody tr td input.input-md {
  width: 220px !important;
}
table.table-form tbody tr td > input {
  max-width: 100% !important;
  min-width: 1% !important;
  width: 100% !important;
  box-sizing: border-box;
  height: 28px !important;
}
table.table-form tbody tr td > .input-append {
  display: flex;
}
table.table-form tbody tr td > .input-append input {
  width: 100%;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

p.inactivo {
  color: #c3c3c3;
}
p.inactivo:after {
  content: "( inactivo )";
  margin-left: 10px;
}

.center {
  text-align: center !important;
}

input.alignCenter {
  margin-left: 45%;
}

.columnHalf {
  margin-left: 0 !important;
  column-count: 2;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  margin-top: 2px;
  margin-bottom: 18px;
}
.columnHalf p {
  margin-bottom: 5px;
}

table.table-historial tbody tr th {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}
table.table-historial tbody tr td h3 {
  color: #585858;
  font-size: 12px;
  line-height: 1.3em;
  margin-bottom: 0.2em;
}
table.table-historial tbody tr td p {
  font-size: 11px;
  line-height: 1.4em;
  margin-bottom: 0;
  font-weight: bold;
  color: #585858;
}
table.table-historial tbody tr td p span {
  font-weight: normal;
  color: #888888;
}
table.table-historial tbody tr td.cnb-highlight {
  background-color: #ffffff;
}
table.table-historial tbody tr td.cnb-highlight h3 {
  opacity: 1;
  color: #B42025;
  font-size: 17px;
}
table.table-historial tbody tr td.cnb-highlight p {
  opacity: 1;
  color: #404040;
  font-size: 13px;
}
td.preferred,
th.preferred,
.table tbody tr:hover td.preferred,
.table tbody tr:hover th.preferred,
.table-striped tbody tr:nth-child(2n+1) td.preferred {
  background-color: #3ec8da;
}

td:nth-child(5).preferred, td:nth-child(6).preferred {
  background-color: #f94141 !important;
}

form.form-full .span12-col > .form-actions {
  padding-left: 0;
}

.table thead th {
  text-transform: capitalize;
  background-color: rgba(0, 0, 0, 0.09);
}
.table tbody th {
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-top-color: #d2d2d2 !important;
}

.container-fluid .table-condensed {
  font-size: 11px;
}
.container-fluid .table-condensed td, .container-fluid .table-condensed th {
  padding: 4px;
  color: #888888;
}
.container-fluid .table-condensed thead tr th {
  line-height: 1.1em;
  color: #333333;
}
.container-fluid .table-condensed tbody tr td {
  line-height: 1.1em;
}
.container-fluid .table-condensed tr.highlight {
  background-color: #ffffff;
}
.container-fluid .table-condensed tr.highlight td {
  background-color: #ffffff;
  color: #404040;
  font-size: 13px;
  font-weight: bold;
}
.container-fluid .table-condensed tr.highlight td:first-child {
  color: #B42025;
}
.container-fluid .table-condensed tr.highlight th {
  background-color: #ffffff !important;
  color: #B42025;
  font-size: 13px;
}

table.table-font thead tr th {
  font-size: 11px;
}
table.table-font thead tr th.cnb-highlight {
  background-color: #ffffff;
  color: #B42025;
  font-size: 13px;
}
table.table-font tbody tr th {
  text-transform: capitalize;
}
table.table-font tbody tr td {
  font-size: 11px;
  line-height: 1.3em;
  color: #888888;
}
table.table-font tbody tr td.cnb-highlight {
  font-size: 13px;
  line-height: 1.3em;
  color: #333;
  font-weight: bold;
}

.flexView {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  column-gap: 60px;
  -moz-column-gap: 60px;
  -webkit-column-gap: 60px;
  margin-bottom: 1em;
}
.flexView .control-group {
  margin-top: 0;
}

.flexViewFull {
  margin-bottom: 1em;
}
.flexViewFull .control-group {
  margin-top: 0;
}
.flexViewFull .control-group .controls ul.image li {
  display: inline-block;
  width: 100%;
}
.flexViewFull .control-group .controls ul.image li a {
  max-width: 550px;
}
.control-group .controls ul.image,
.control-group .controls ul.image-group {
  margin-left: 10px;
  list-style: none;
}
.control-group .controls ul.image li a,
.control-group .controls ul.image-group li a {
  display: block;
}
.control-group .controls ul.image li a img,
.control-group .controls ul.image-group li a img {
  width: 100%;
}
.control-group .controls ul.image-group {
  column-count: 3;
}
.control-group .controls ul.image-group li {
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
}
/*-- Iconos --*/
.icon-page-go,
.icon-file-page,
.icon-search,
.icon-file-search,
.icono-advertencia {
  display: inline-block;
  width: 17px;
  height: 16px;
  margin-right: 0.2em;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url("../img/famfamfam-icons.png");
  background-position: 14px 14px;
  background-repeat: no-repeat;
}
.icon-page-go:hover,
.icon-file-page:hover,
.icon-search:hover,
.icon-file-search:hover,
.icono-advertencia:hover {
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

.icon-page-go {
  background-position: -797px -84px;
}

.icon-file-page {
  background-position: -756px -63px;
}

.icon-search {
  background-position: -294px -82px;
}

.icon-file-search {
  background-position: -1682px -84px;
}

.icono-advertencia {
  background-position: -1616px -41px;
}

.wizardHeader {
  margin-bottom: 1em;
}
.wizardHeader ul {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: stretch;
  padding-top: 1em;
}
.wizardHeader ul li {
  position: relative;
  text-align: center;
  width: 100%;
  list-style: none;
}
.wizardHeader ul li a {
  display: block;
  transition: 1s all;
}
.wizardHeader ul li a .number {
  transition: 1s all;
}
.wizardHeader ul li a:hover {
  text-decoration: none;
}
.wizardHeader ul li a:hover .number {
  background-color: #f39c12;
  border-color: #f39c12;
}
.wizardHeader ul li a:hover p {
  color: #f39c12;
}
.wizardHeader ul li:before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0;
  width: 50%;
  height: 2px;
  background-color: #c3c3c3;
  z-index: 500;
}
.wizardHeader ul li:after {
  content: "";
  position: absolute;
  top: 13px;
  right: 0;
  width: 50%;
  height: 2px;
  background-color: #c3c3c3;
  z-index: 500;
}
.wizardHeader ul li:first-child:before, .wizardHeader ul li:last-child:after {
  display: none;
}
.wizardHeader ul li .number {
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.2em;
  border-radius: 50%;
  background-color: #c3c3c3;
  color: #ffffff;
  font-size: 12px;
  line-height: 24px;
  z-index: 600;
  position: relative;
  border: 2px solid #c3c3c3;
}
.wizardHeader ul li p {
  text-transform: uppercase;
  font-size: 11px;
  padding: 0.2em;
  color: #757575;
}
.wizardHeader ul li.active a:hover p {
  color: #ffffff;
}
.wizardHeader ul li.active .number {
  border-color: #c87f0a;
  background-color: #f39c12;
}
.wizardHeader ul li.active p {
  background-color: #f39c12;
  color: #ffffff;
}
.wizardHeader ul li.disabled {
  opacity: 0.5;
}
.wizardHeader ul li.disabled a {
  cursor: not-allowed;
}
.wizardHeader ul li.disabled a:hover .number {
  background-color: #c3c3c3;
  border-color: #c3c3c3;
}
.wizardHeader ul li.disabled a:hover p {
  color: #757575;
}

.form-horizontal .wizardContainer .form-actions {
  text-align: center;
  padding: 1em;
}

.headerForm {
  display: flex;
  align-items: stretch;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.8em;
  margin-bottom: 1em;
}
.headerForm .brand,
.headerForm .info {
  width: 100%;
}
.headerForm .brand img {
  display: inline-block;
  height: 50px;
  width: auto;
  margin-right: 2em;
}
.headerForm .brand h3 {
  font-size: 22px;
  display: inline-block;
}
.headerForm .info {
  padding-top: 0.2em;
}
.headerForm .info .control-group {
  margin-top: 0;
  margin-bottom: 0;
}
.headerForm .info .control-group label.control-label {
  padding-top: 0;
  font-size: 12px;
  text-align: center;
  color: #757575;
  display: block;
  margin-bottom: 0;
  width: 100%;
}
.headerForm .info .control-group .controls {
  text-align: center;
  margin-left: 0;
  display: block;
  vertical-align: middle;
  position: relative;
}
.headerForm .info .control-group .controls select,
.headerForm .info .control-group .controls .select2-container {
  margin-bottom: 0;
  text-align: left;
}
.headerForm .info .control-group .controls .spinner {
  display: none;
}
.headerForm p {
  font-size: 12px;
  text-align: center;
  color: #757575;
  margin: 0;
}
.headerForm p strong {
  color: #333;
  font-size: 15px;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.span12-col {
  position: relative;
}

.btn-container {
  position: absolute;
  top: 8px;
  right: 8px;
}

.space {
  float: left;
  width: 100%;
  height: 20px;
}

.spinner {
  display: none !important;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
.spinner.show {
  display: inline-block !important;
  display: inline-block;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.styleAccordion {
  border: 0;
  box-shadow: none;
}
.styleAccordion .ui-state-default .ui-icon {
  background-position: -288px -120px;
}
.styleAccordion .ui-icon {
  background-image: url("../img/glyphicons-halflings.png") !important;
  width: 17px;
  height: 16px;
  background-position: -313px -119px;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -ms-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}
.styleAccordion .accordion-group {
  border: 0;
  box-shadow: none;
}
.styleAccordion .accordion-group h3 {
  box-shadow: none;
  background-image: none;
  background-color: transparent;
  padding-left: 28px;
  font-size: 15px;
  margin: 0;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  color: #404040;
}
.styleAccordion .accordion-group h3:hover, .styleAccordion .accordion-group h3.ui-state-active {
  color: #f39c12;
}
.styleAccordion .accordion-group .accordion-inner {
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 0;
  padding: 0.8em;
}
.styleAccordion .accordion-group .accordion-inner .item {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  padding: 0.2em 0;
  border-bottom: 1px solid #f2f2f2;
}
.styleAccordion .accordion-group .accordion-inner .item label {
  padding-right: 0.3em;
  cursor: pointer;
}
.styleAccordion .accordion-group .accordion-inner .item label input {
  margin-right: 4px;
}
.styleAccordion .accordion-group .accordion-inner .item a, .styleAccordion .accordion-group .accordion-inner .item button {
  min-width: 40px;
  text-align: center;
}
.styleAccordion .accordion-group .accordion-inner .item a i, .styleAccordion .accordion-group .accordion-inner .item button i {
  margin-right: 0;
}

.modal.special .modal-header {
  background-color: #B42025;
}
.modal.special .modal-header h3 {
  margin-top: 0.6em;
  margin-bottom: 0.2em;
  color: #ffffff;
  display: inline-block;
  font-size: 18px;
}
.modal.special .modal-header h3 span {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  margin-right: 10px;
}
.modal.special .modal-body {
  max-height: 600px;
}
.modal.special .modal-body .label {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.alert p .badge {
  font-size: 14px !important;
  background-color: #B42025;
}

.widgetBlock.highlight {
  border: 4px solid #B42025;
}
.widgetBlock.highlight .number {
  background-color: #B42025;
}
.widgetBlock.highlight .title h3 {
  background-color: #B42025;
}

@media (max-width: 799px) {
  .span6-col {
    width: 100%;
  }

  .navbar .btn-navbar {
    float: left;
  }

  .panel-area {
    padding-top: 0;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .flexView {
    column-count: 1;
  }

  .tableResponsive {
    overflow: overlay;
  }

  .headerForm {
    display: block;
    float: left;
    width: 100%;
  }
  .headerForm .info {
    width: 50%;
    display: inline-block;
    float: left;
  }
}
@media (max-width: 767px) {
  .controls input {
    width: 100%;
    max-width: -webkit-fill-available;
    max-width: -moz-available;
  }
  .controls > .select2-container {
    max-width: 100%;
  }
}
@media (max-width: 680px) {
  .well {
    overflow: auto;
  }

  .form-horizontal.large .control-group label.control-label {
    width: 100% !important;
    text-align: left;
    margin: 0;
    padding: 0;
  }
  .form-horizontal.large .control-group .controls {
    margin-left: 0;
  }

  .control-group .controls ul.image-group {
    column-count: 2;
  }
}
@media (max-width: 550px) {
  .headerForm .info {
    width: 100%;
    margin-bottom: 1em;
    text-align: left;
  }

  .btn-container {
    position: relative;
    top: 0 !important;
    right: initial !important;
    margin-bottom: 1em;
  }
}
@media (max-width: 480px) {
  .form-horizontal .outside-help {
    margin-left: 0;
  }
  .form-horizontal .help-inline {
    display: block;
  }

  .control-group .controls ul.image,
.control-group .controls ul.image-group {
    margin-left: 0;
  }
  .control-group .controls ul.image-group {
    column-count: 1;
  }
}
/*! ========================================================================
 * Bootstrap Toggle: bootstrap-toggle.css v2.2.0
 * http://www.bootstraptoggle.com
 * ========================================================================
 * Copyright 2014 Min Hur, The New York Times Company
 * Licensed under MIT
 * ======================================================================== */
.checkbox label .toggle,
.checkbox-inline .toggle {
  margin-left: -20px;
  margin-right: 5px;
}

.toggle {
  position: relative;
  overflow: hidden;
}

.toggle input[type=checkbox] {
  display: none;
}

.toggle-group {
  position: absolute;
  width: 200%;
  top: 0;
  bottom: 0;
  left: 0;
  transition: left 0.35s;
  -webkit-transition: left 0.35s;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.toggle.off .toggle-group {
  left: -100%;
}

.toggle-on {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.toggle-off {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.toggle-handle {
  position: relative;
  margin: 0 auto;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
  width: 0px;
  border-width: 0 1px;
}

.toggle.btn {
  min-width: 59px;
  min-height: 34px;
}

.toggle-on.btn {
  padding-right: 24px;
}

.toggle-off.btn {
  padding-left: 24px;
}

.toggle.btn-lg {
  min-width: 79px;
  min-height: 45px;
}

.toggle-on.btn-lg {
  padding-right: 31px;
}

.toggle-off.btn-lg {
  padding-left: 31px;
}

.toggle-handle.btn-lg {
  width: 40px;
}

.toggle.btn-sm {
  min-width: 50px;
  min-height: 30px;
}

.toggle-on.btn-sm {
  padding-right: 20px;
}

.toggle-off.btn-sm {
  padding-left: 20px;
}

.toggle.btn-xs {
  min-width: 35px;
  min-height: 22px;
}

.toggle-on.btn-xs {
  padding-right: 12px;
}

.toggle-off.btn-xs {
  padding-left: 12px;
}

.toggle.btn.btn-main .toggle-group {
  background-color: #52b8e8 !important;
}
.toggle.btn {
  height: 20px !important;
  min-height: 20px !important;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 18px;
  border: 3px solid #eceeef;
  border-bottom-color: rgba(255, 255, 255, 0.6);
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  background-image: -webkit-linear-gradient(top, #eeeeee, white 25px);
  background-image: -moz-linear-gradient(top, #eeeeee, white 25px);
  background-image: -o-linear-gradient(top, #eeeeee, white 25px);
  background-image: linear-gradient(to bottom, #eeeeee, white 25px);
}
.toggle.btn .toggle-group {
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  background-image: -webkit-linear-gradient(top, #eeeeee, white 25px);
  background-image: -moz-linear-gradient(top, #eeeeee, white 25px);
  background-image: -o-linear-gradient(top, #eeeeee, white 25px);
  background-image: linear-gradient(to bottom, #eeeeee, white 25px);
}
.toggle.btn .toggle-group .btn {
  background-image: none;
  transition: 0.15s ease-out;
  text-shadow: none;
  font-size: 11px;
  line-height: 11px;
  border: 1px solid #d4d4d4;
  border-radius: 18px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.toggle.btn .toggle-group .btn.toggle-on {
  background-color: #52b8e8;
  color: #ffffff;
  border: 1px solid #1c97d2;
  padding-left: 10px;
}
.toggle.btn .toggle-group .btn.toggle-off {
  background-color: #eceeef;
  color: #aaa;
  padding-right: 8px;
}

.toggle-handle.btn {
  width: 12px;
  height: 12px;
  border-radius: 9px;
  padding: 0;
  border: 3px solid #ffffff !important;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5) !important;
  background-image: -webkit-linear-gradient(top, white 30%, #e4e4e4) !important;
  background-image: -moz-linear-gradient(top, white 30%, #e4e4e4) !important;
  background-image: -o-linear-gradient(top, white 30%, #e4e4e4) !important;
  background-image: linear-gradient(0deg, white 30%, #e4e4e4) !important;
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.toggle-handle.btn:hover {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3) !important;
  background-image: -webkit-linear-gradient(top, white 30%, #e4e4e4) !important;
  background-image: -moz-linear-gradient(top, white 30%, #e4e4e4) !important;
  background-image: -o-linear-gradient(top, white 30%, #e4e4e4) !important;
  background-image: linear-gradient(0deg, white 30%, #e4e4e4) !important;
}

.toggle.btn .toggle-handle {
  margin-right: 19px;
}

.toggle.btn.off .toggle-handle {
  margin-left: 40px;
}
.toggle.btn.off .toggle-handle:hover {
  background-color: #f2f2f2;
}

/*# sourceMappingURL=style.css.map */
